import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CarouselComponent from './components/CarouselComponent';
import QuienesSomos from './components/QuienesSomos';
import Inicio from './components/Inicio';
import EventsComponent from './components/EventsComponent';
import NuestraMision from './components/NuestraMision';
import Vision from './components/Vision';
import Sellos from './components/Sellos';
import Valores from './components/Valores';
import Contacto from './components/Contacto';
import ListaProfesores from './components/ListaProfesores';
import DocumentList from './components/DocumentList'
import ListaUtiles from './components/ListaUtiles'
import Headers from './components/Header'
import YouTubeVideo from './components/YouTubeVideo'
import Proyectos from './components/Proyectos'
import InstagramGallery from './components/InstagramGallery'




function App() {

  const videoUrls = [
    'https://www.youtube.com/watch?v=_naNticc0Yg',
    'https://www.youtube.com/watch?v=n4S4_qOkFhA',
    'https://www.youtube.com/watch?v=XWl5j7wZv84&t=8s',
    'https://www.youtube.com/watch?v=9vmXY_KUhH8',
    'https://www.youtube.com/watch?v=_ZOAevMy7yo&t=9s',
    'https://www.youtube.com/watch?v=f-xUlObYxDM',

    
    // ...otros enlaces de videos
  ];
  return (
    <div className="App">
      <Headers/>
      
      <Navbar />

      <Inicio/>

      <CarouselComponent/>

      <EventsComponent/>

      <QuienesSomos/>

      <ListaProfesores/>

      <NuestraMision/>

      <Vision/>
      
      <Sellos/>

      <Valores/>

      <YouTubeVideo urls={videoUrls} />

      <InstagramGallery/>

      <Proyectos/>

      <DocumentList/>

      <ListaUtiles/>

      <Contacto/>

      <Footer />

     
    </div>
  );
}

export default App;
