import React from 'react';
import '../App.css'; // Archivo de estilos
import instagran1 from '../img/carrusel28.jpg'; // Importa tu imagen
import instagran2 from '../img/carrusel29.jpg'; // Importa más imágenes
import instagran3 from '../img/carrusel19.webp'; // Importa más imágenes
import instagran4 from '../img/carrusel30.jpg'; // Importa más imágenes
import instagran5 from '../img/carrusel31.jpg'; // Importa más imágenes
import instagran6 from '../img/carrusel32.jpg'; // Importa más imágenes
import instagran7 from '../img/carrusel33.jpg'; // Importa más imágenes
import instagran8 from '../img/carrusel34.jpg'; // Importa más imágenes
import instagran9 from '../img/carrusel35.jpg'; // Importa más imágenes
import instagran10 from '../img/carrusel36.jpg'; // Importa más imágenes
import instagran11 from '../img/carrusel37.jpg'; // Importa más imágenes
import instagran12 from '../img/carrusel38.jpg'; // Importa más imágenes
import instagran13 from '../img/carrusel39.jpg'; // Importa más imágenes
import instagran14 from '../img/carrusel40.jpg'; // Importa más imágenes

class InstagramGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photos: [

                        
                {
                  url: 'https://www.instagram.com/nuevazelandiaescuela/p/DDW4KICuOep/',
                  imageUrl: instagran14, // Otra imagen importada
                },


                {
                    url: 'https://www.instagram.com/p/DCzh2X7Ravk/',
                    imageUrl: instagran13, // Otra imagen importada
                },




                {
                    url: 'https://www.instagram.com/p/DChibvfPj8n/', // Link a Instagram
                    imageUrl: instagran1, // Imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/DCZ50beykXf/',
                    imageUrl: instagran2, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/DChiGIVREhg/',
                    imageUrl: instagran3, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/DCZ64JZSVoo/',
                    imageUrl: instagran4, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/DB9UAJJRtZk/',
                    imageUrl: instagran5, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/DBOmmnbu0bW/',
                    imageUrl: instagran6, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/DBJ_72Wyoxi/',
                    imageUrl: instagran7, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/C-3yDgGuOlb/',
                    imageUrl: instagran8, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/C-3xbqfuWnF/',
                    imageUrl: instagran9, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/C-Is9qsu6ug/',
                    imageUrl: instagran10, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/C-GVhE7S_ka/',
                    imageUrl: instagran11, // Otra imagen importada
                },
                {
                    url: 'https://www.instagram.com/p/C7zmZ2QpQ5l/',
                    imageUrl: instagran12, // Otra imagen importada
                },
               
            ],
        };
    }

    render() {
        const { photos } = this.state;

        return (
            <div className="Instagram-Galleria" id="Instagram-list">
                <h2 className="galeria-title">Galería Instagram</h2> {/* Título agregado */}
                <div className="photos-list">
                    <div className="photo-cards">
                        {photos.map((photo, index) => (
                            <div
                                key={index}
                                className="photo-card"
                                style={{
                                    backgroundImage: `url(${photo.imageUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <a
                                    href={photo.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="photo-link"
                                >
                                    {/* Enlace al post de Instagram */}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default InstagramGallery;
