import React, { useState, useEffect, useRef } from 'react';
import FloatingButton from './FloatingButton'; // Asegúrate de ajustar la ruta correcta
import Logo from '../components/Logo'; // Asegúrate de ajustar la ruta correcta para el archivo del logotipo

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [conocenosOpen, setConocenosOpen] = useState(false);
  const [utilesOpen, setUtilesOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleConocenos = () => {
    setConocenosOpen(!conocenosOpen);
  };

  const toggleUtiles = () => {
    setUtilesOpen(!utilesOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setConocenosOpen(false);
    setUtilesOpen(false);
  };

  const handleReservarClick = () => {
    /* setPassword(''); // Limpia el campo de la clave antes de abrir el modal
    setErrorMessage(''); // Limpia el mensaje de error */
    setIsModalOpen(true); // Abre el modal para ingresar la clave
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'ENZ8593') {
      window.open('https://sites.google.com/nzelandia.cl/centro-de-innovacion/inicio?pli=1&authuser=1', '_blank');
      setPassword(''); // Limpia el campo de la clave antes de abrir el modal
      setErrorMessage(''); // Limpia el mensaje de error
      setIsModalOpen(false); // Cierra el modal
     
    } else {

      setErrorMessage('Clave incorrecta, por favor intente nuevamente.');
      setPassword(''); // Limpia el campo de la clave
      setIsModalOpen(false); // Cierra el modal
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`navbar ${menuOpen ? 'abierto' : ''}`} ref={navbarRef}>
      <div className="logo-container">
        {/* <Logo /> */}
      </div>
      <div className="hamburguesa" onClick={toggleMenu}>
        <div className="barra"></div>
        <div className="barra"></div>
        <div className="barra"></div>
      </div>
      <ul className={`menu ${menuOpen ? 'abierto' : ''}`}>
        <li><a href="#inicio" onClick={closeMenu}>Inicio</a></li>
        <li><a href="#events-component" onClick={closeMenu}>Informaciones</a></li>
        <li className={`dropdown ${conocenosOpen ? 'abierto' : ''}`}>
          <a href="#" onClick={toggleConocenos}>Conócenos</a>
          <ul className={`submenu ${conocenosOpen ? 'abierto' : ''}`}>
            <li><a href="#quienes-somos" onClick={closeMenu}>Quiénes Somos</a></li>
            <li><a href="#nuestra-mision" onClick={closeMenu}>Nuestra Misión</a></li>
            <li><a href="#vision" onClick={closeMenu}>Visión</a></li>
            <li><a href="#sellos" onClick={closeMenu}>Sellos</a></li>
            <li><a href="#valores" onClick={closeMenu}>Valores</a></li>
          </ul>
        </li>
        <li><a href="#videos-container" onClick={closeMenu}>Galería Audiovisual</a></li>
        <li><a href="#Instagram-list" onClick={closeMenu}>Galería Instagram</a></li>

        <li><a href="https://padlet.com/g4pzjdvh65/talleres-jec-de-artes-dggvfttlwwonztsd" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Galería de Arte</a></li>
        <li><a href="#proyecto-container" onClick={closeMenu}>Proyectos</a></li>
        <li><a href="#documentos" onClick={closeMenu}>Documentos Institucionales</a></li>
        
        {/* Opción de Reservar */}
        <li>
          <a href="#" onClick={handleReservarClick} title="">
            Reservar
          </a>
        </li>
        
        <li><a href="#contacto" onClick={closeMenu}>Contacto</a></li>
        <FloatingButton />
      </ul>

      {/* Modal para ingresar la clave */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Ingrese la clave</h2>
            <form onSubmit={handlePasswordSubmit}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Clave de acceso"
              />
              <button type="submit">Acceder</button>
            </form>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <button onClick={() => setIsModalOpen(false)}>Cerrar</button>
          </div>
        </div>
      )}

      {/* Estilos para el modal */}
      <style jsx>{`
        .navbar {
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #285599;
          padding: 1rem;
          border-bottom: 3px solid red;
          border-top: 3px solid red;
          background: linear-gradient(to bottom, #285599, #002a5c);
          position: relative;
          top: -80px;
        }

        .logo-container {
          display: flex;
          align-items: center;
        }

        .hamburguesa {
          display: none;
          cursor: pointer;
        }

        .barra {
          width: 30px;
          height: 3px;
          background-color: white;
          margin: 6px 0;
        }

        .menu {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
        }

        .menu li {
          margin: 0 1rem;
          position: relative;
        }

        .menu a {
          text-decoration: none;
          color: white;
          position: relative;
        }

        .menu a:hover {
          color: #FFD700;
        }

        .menu a::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background: #FFD700;
          transition: width 0.3s;
          position: absolute;
          bottom: -5px;
          left: 0;
        }

        .menu a:hover::after {
          width: 100%;
        }

        .submenu {
          display: none;
          list-style: none;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #285599;
          padding: 0.5rem;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        .dropdown:hover .submenu {
          display: block;
          opacity: 1;
        }

        .submenu li {
          margin: 0.5rem 0;
          padding: 3px;
        }

        .submenu a {
          text-decoration: none;
          color: white;
          display: block;
        }

        @media (max-width: 768px) {
          .hamburguesa {
            display: block;
          }

          .menu {
            display: none;
            position: absolute;
            top: 200px;
            right: 0;
            background-color: #285599;
            width: 100%;
            text-align: center;
            z-index: 1000;
          }

          .menu.abierto {
            display: flex;
            flex-direction: column;
          }

          .menu li {
            margin: 1rem 0;
          }

          .dropdown:hover .submenu {
            display: block;
            opacity: 1;
          }
        }

        @media (min-width: 769px) {
          .menu {
            position: relative;
            top: 0;
          }

          .menu.abierto {
            display: flex;
            flex-direction: row;
            background-color: transparent;
            width: auto;
          }

          .menu li {
            margin: 0 1rem;
          }

          .dropdown:hover .submenu {
            display: block;
            opacity: 1;
          }
        }

        /* Estilos para el modal */
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1001;
        }

        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 8px;
          width: 300px;
          text-align: center;
        }

        input {
          margin-bottom: 10px;
          padding: 8px;
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        button {
          padding: 10px 20px;
          background-color: #285599;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        button:hover {
          background-color: #003366;
        }

        .error {
          color: red;
          margin-top: 10px;
        }
      `}</style>
    </nav>
  );
}

export default Navbar;
